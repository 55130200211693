import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import iPad01 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(26).webp'
import iPad02 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(27).webp'
import iPad03 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(28).webp'

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="The Settings button takes you to the general settings of the app."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="The settings menu also includes a help section."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="Under the item Buttons you will find a description of all function buttons of the app. See also the Multview menu."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function SettingGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}


export default SettingGallery